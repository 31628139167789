.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;

  & :global {

    .container {
      padding: 0;
      background: #FFFFFF;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
      border-radius: 6px;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .title {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 6px;
        margin: 0;
        font-weight: 400;
        text-align: center;
        min-height: 40px;
      }

      .image-container {

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
