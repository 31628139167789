.page .main-header {
    height: 620px; }

.page-header {
    .main-smart-home-title {
        margin: 125px 0 30px; }

    .main-smart-home-subtitle {
        font-size: 22px; } }

.main-application-button-container {
    display: flex;
    justify-content: center; }

.main-application-button-space-top {
    margin-top: 38px; }

.main-application-button {
    margin: 60px 0 70px; }

.page-description .main-our-instagram-title {
    margin: 30px 0;
    font-size: 30px;
    text-align: center; }

.feedback-form-container {
    visibility: hidden;
    opacity: 0;
    transition: all 2s ease;
    &.visible-form {
        visibility: visible;
        opacity: 1; } }
