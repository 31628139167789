@media (min-width: 1200px) {
    .howwework-block {
        max-width: 1140px; }

    .authorize-block {
        max-width: 1140px; }

    .custom-container {
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto; }

    .page {
        &-description {
            padding: 0 90px; }
        &-photo {
            width: 100%; } }

    .ecosystem {
        &-description {
            padding: 0 90px; } } }

@media (min-width: 801px ) {
    .ecosystem-description {
        h3 {
            width: 50%; } } }

@media screen and (max-width: 800px) {
    .ecosystem-description {
        p {
            img {
                margin-top: unset; } } } }



@media screen and (max-width: 991px) {
    .solutions {
        background-image: url('./images/Feedback/feedback-big-bg.png');
        background-color: rgba(250, 250, 250, 1);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover; }
    .tabs-render-ssr {
        display: block;
        width: 100%; } }




@media (min-width: 992px) and (max-width: 1199px) {

    .authorize-first-row {
        display: none; }

    .page {
        &-description {
            padding: 0; }
        &-header {
            p {
                padding: 0; } }
        &-products {
            flex-wrap: wrap; } }

    .feedback-small {
        height: 287px;
        .container {
            flex-direction: column;
            justify-content: center;
            p {
                text-align: center; }

            h3 {
                text-align: center; } } }

    .services {
        &-table {
            padding: 0 10px; } }

    .instagram-container {
        padding-left: 0;
        padding-right: 0; }

    .footer {
        &-row {}

        &-block {
            margin-bottom: 50px;
            &-item {
                flex-direction: column; } } }


    .ecosystem {
        &-description {
            padding: 0; } }

    .solutions-block {
        margin-top: 0px;
        background-color: rgba(250, 250, 250, 1);
        background-position: center center;
        background-repeat: no-repeat;


        &-item {
            width: 105px; }
        .container {
            max-width: unset; }

        .row {
            justify-content: space-between; } }

    .nav .services-block {
        background-color: rgba(250, 250, 250, 1); }

    .howwework-block button {
        width: 120px; }

    .howwework-description-item {
        width: 60%; }

    .form-control-placeholder {
        left: 20%; }

    .authorize-block button {
        width: 160px; }

    .ecosystem {
        &-description {

            p {

                img {
                    width: 450px; } } } }

    .contacts-row {
        flex-direction: column;
        justify-content: center;
        align-items: center; }

    .control {

        &-block {
            &-row {}

            &-item {
                h3 {
                    font-size: 18px; } }
            &-header {} } }




    .floating-label label {
        left: 20.5%; }

    .floating-label-div {
        left: 20.5%; }

    .slick-prev {
        left: -5%; }
    .slick-next {
        right: -5%; }

    .ecosystem-devices .control-hover-text {
        width: 290%; } }

@media (min-width: 769px) and (max-width: 991px) {

    body {
        font-size: 14px; }



    .authorize-first-row {
        display: none; }

    .nav {
        display: none;
        &-narrow {
            padding: 0;
            background-color: $white; } }

    .navbar {
        &-ul {
            display: none; }
        &-narrow {
            height: 80px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; }
        &-icon:after,
        &-icon:before,
        &-icon div {
            content: '';
            background-image: url('./images/menu-bar-item.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 41px;
            height: 4px;
            margin: 0px;
            transition: all .2s ease-in-out; } }

    .howwework {
        &-description {
            &-item {
                width: 60%;
                h6 {
                    display: none; } } } }

    .page-nav ul {}


    .control {
        &-block {
            padding: 0 20px;

            &-row {
                justify-content: space-between; }
            &-header {
                width: 20%; }
            &-item {
                h3 {
                    font-size: 16px; }

                &-button {
                    button {
                        font-size: 11px; } } } }

        &-devices {
            padding: 0; } }




    .modal-dialog {
        max-width: 600px; }

    .instagram-container {
        padding-left: 0;
        padding-right: 0; }

    .page {
        &-description {
            padding: 0; }
        &-header {
            p {
                padding: 0; } }
        &-products {
            flex-wrap: wrap; } }

    .feedback-small {
        height: 287px;
        .container {
            flex-direction: column;
            justify-content: center;
            p {
                text-align: center; }
            h3 {
                text-align: center; } } }

    .feedback-big-form {
            width: 70%; }
    .services {
        &-table {
            padding: 0 10px; } }

    .footer {
        &-row {}

        &-block {
            margin-bottom: 50px;
            &-item {
                flex-direction: column; } } }

    .not-found {
        img {
            width: 90%; } }

    .ecosystem {
        &-description {
            padding: 0; } }

    .solutions-block {
        margin-top: 0px;
        .container {
            max-width: unset; } }

    .form-control-placeholder {
        left: 16%; }

    .feedback-coop {
        .form-control-placeholder {
            left: 10%; } }

    .footer-container {

            border-image-outset: 3px 0 15px 0; }

    .contacts {
        .row {
            flex-direction: column;
            align-items: center;
            justify-content: center; } }

    .ecosystem {
        &-description {

            p {

                img {
                    width: 346px; } } } }

    .slick-prev {
        left: 2%; }
    .slick-next {
        right: 2%; }

    .ecosystem-devices .control-hover-text {
        width: 170%; }

    .authorize-block {
        button {
            width: 135px; } }

    .instagram .instagram-hover-text {
        top: 6%;
        font-size: 11px;
        padding: 0 5px; }

    .floating-label label {
        left: 16.5%; }

    .floating-label-div {
        left: 16.5%; }

    .feedback-coop-form-input {
        .floating-label label {
            left: 10.5%; }

        .floating-label div {
            left: 10.5%; } }


    .instagram-container {
        margin-bottom: 94px; } }







@media (min-width: 577px) and (max-width: 768px) {

    body {
        font-size: 14px; }



    .authorize-first-row {
        display: none; }

    .nav {
        display: none;
        &-narrow {
            padding: 0;
            background-color: $white; } }

    .modal-dialog {
        max-width: 600px; }

    .navbar {
        &-ul {
            display: none; }
        &-narrow {
            height: 80px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; }
        &-icon:after,
        &-icon:before,
        &-icon div {
            content: '';
            background-image: url('./images/menu-bar-item.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 41px;
            height: 4px;
            margin: 0px;
            transition: all .2s ease-in-out; } }

    .search-narrow {
        padding: 0px 15px; }

    .page-nav ul {}


    .instagram-container {
        padding-left: 0;
        padding-right: 0; }

    .navbar {
        display: none; }
    .page {
        &-description {
            padding: 0; }
        &-header {
            p {
                padding: 0; } }
        &-products {
            flex-wrap: wrap; } }

    .feedback-small {
        height: 287px;
        .container {
            flex-direction: column;
            justify-content: center;
            p {
                text-align: center; }

            h3 {
                text-align: center; } } }
    .feedback-big-form {
        width: 90%; }
    .feedback-coop-form {
        width: 90%; }





    .services {
        &-table {
            padding: 0 10px;

            h4, h5 {
                font-size: 13px;
                padding: 0; } } }

    .control {
        &-block {
            padding: 0 25px;
            &-header {
                width: 20%; }
            &-row {
                justify-content: space-around; }
            &-item {
                h3 {
                    font-size: 12px; }

                &-button {
                    button {
                        padding: 4px 10px;
                        font-size: 10px;
                        height: 40px; } } } }
        &-devices {
            padding: 0; } }





    .footer {

        &-row {}

        &-block {
            &-item {
                flex-direction: column; } }
        &-credentials {
            flex-direction: column; } }
    .not-found {
        img {
            width: 100%; } }

    .ecosystem {
        margin-bottom: 0;
        &-header {
            h1 {}

            img {
                width: 90%; } }

        &-description {
            padding: 0; } }

    .howwework-description-item h6 {
        display: none; }

    .howwework-description-item {
        width: 100%;
        .arrow {
            display: none; } }

    .howwework-block button {
        width: 100%; }

    .form-control-placeholder {
        left: 14%; }

    .footer-container {
            border-image-outset: 2px 0 15px 0; }

    .footer-credentials {
        display: flex;
        justify-content: space-between;
        height: 105px; }


    .ecosystem {
        &-description {
            p {

                img {
                    width: 346px; } } } }

    .slick-prev {
        left: 2%; }
    .slick-next {
        right: 2%; }

    .ecosystem-devices .control-hover-text {
        width: 120%; }

    .instagram-container {
        margin-bottom: 85px; }

    .instagram .instagram-hover-text {
        top: 0%;
        font-size: 9px;
        padding: 0; }

    .floating-label label {
        left: 15%; }

    .floating-label-div {
        left: 15%; } }




@media screen and (max-width: 577px) {

    body {
        font-size: 14px; }


    .authorize-first-row {
        display: none; }

    .scenario {
        display: none; }
    .nav {
        display: none;
        &-narrow {
            padding: 0;
            background-color: $white; } }


    .control {
        &-block {
            padding: 0 25px;
            &-header {
                width: 80%; }
            &-row {
                justify-content: space-around; } }

        &-devices {
            display: none; } }




    .devices {
        display: block;

        .control-hover-text {
            width: 60%; } }

    .navbar {
        &-ul {
            display: none; }
        &-narrow {
            height: 80px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; }
        &-icon:after,
        &-icon:before,
        &-icon div {
            content: '';
            background-image: url('./images/menu-bar-item.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 41px;
            height: 4px;
            margin: 0px;
            transition: all .2s ease-in-out; } }


    .instagram-container {
        padding-left: 0;
        padding-right: 0; }

    .search-narrow {
        &-delimiter {
            width: 90%; }
        div {
            flex-direction: column;
            div {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 35px; }
            button {
                margin-left: auto;
                margin-right: auto; } } }

    .search-header {
        div {
            flex-direction: column;
            align-items: center; }
        button {
            margin-top: 40px; }
        input {
            width: 100%;
            margin-left: auto;
            margin-right: auto; } }

    .page-nav ul {}


    .page {
        &-header {
            h1 {
                margin-top: 120px;
                text-align: center;
                margin-bottom: 50px; }

            p {
                padding: 0 10px; } }
        &-description {
            padding: 0; }
        &-products {
            flex-wrap: wrap; } }

    .feedback-small {
        height: unset;
        .container {
            flex-direction: column;
            justify-content: center;
            p {
                text-align: center;
                width: unset;
                padding: 0 20px;
                margin-top: 75px; }

            h3 {
                text-align: center; } }

        &-buttons {
            width: 100%;
            form {
                flex-direction: column; }

            input {
                margin-bottom: 24px;
                width: 100%;
                border-radius: 50px; }
            button {
                width: 70%;
                border-radius: 50px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 60px; } } }


    .feedback-big-form {
            width: 90%;
            &-input {
                input {
                    width: 90%; }
                textarea {
                    width: 90%; }
                .dragndrop {
                    width: 90%; }

                input[type="checkbox"] {
                    & + label:before {
                        width: 40px;
                        height: 30px; } } } }

    .feedback-coop {

        &-form {
            width: 100%;
            &-input {
                input, textarea {
                    width: 90%; } }
            p {
                width: 90%; } }

        &-buttons {
            div {
                flex-direction: row;
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                justify-content: space-between;
                button {
                    margin: 12px 0px; } } } }

    .services {
        &-table {

            padding: 0 10px;
            h4, h5 {
                font-size: 13px;
                padding: 0; }
            &-header-row {
                .col-3 {
                    padding: 0 5px; }
                h5 {
                    font-size: 10px; } }
            .row {
                margin: 0; } } }


    .control-block button.active {
        border: none; }


    .footer {
        &-block:nth-child(-n+3) {
            display: none; }

        &-row {
            justify-content: space-between; }

        &-block {
            margin-right: auto;
            &-item {
                flex-direction: column; } }
        &-credentials {
            flex-direction: column; } }

    .not-found {
        img {
            width: 100%; } }

    .ecosystem {
        &-header {
            h1 {}



            img {
                width: 90%;
                margin-top: 110px; } }
        &-description {
            padding: 0; } }

    .contacts {
        &-row {
            div {
                a {
                    margin-top: 10px; } } } }

    .howwework-description-item {
        h6 {
            display: none; }

        p {
            padding: 0px 10px; } }

    .howwework-description-item {
        width: 100%;
        .arrow {
            display: none; } }

    .howwework-block button {
        width: 100%; }


    .form-control-placeholder {
        left: 7%; }

    .feedback-big-form-input input[type=checkbox]:checked+label:after {

        position: absolute;
        left: 6px;
        top: -10px;
        font-size: 32px;
        color: $blue;
        width: 5px;
        height: 10px; }

    .footer-contacts {
        height: 80px;
        justify-content: space-between;

        &-item {
            margin-bottom: unset;
            padding-bottom: 15px; } }

    .footer-credentials-first {
        margin-top: 45px; }

    .footer-credentials {
        button {
            opacity: 0.3; }

        p {
            opacity: 0.3; } }

    .footer-container {
            border-image-outset: 2px 0 15px 0; }


    .footer-credentials {
        display: flex;
        justify-content: space-between;
        height: 105px; }

    #footer-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        div {
            margin: 5px 0; } }

    #footer-first {
        height: 15px;
        order: 1; }


    #footer-second {
        order: 3; }



    #footer-third {
        order: 2; }




    .feedback-big-form-input .checkbox {
        width: 90%;
        margin-left: auto;
        margin-right: auto; }

    .slick-prev {
        left: 2%; }
    .slick-next {
        right: 2%; }


    .floating-label label {
        left: 7%; }

    .floating-label-div {
        left: 7%;
        width: 87.5%; }


    .instagram-container {
        margin-bottom: 55px; }


    .footer a {
        font-size: 9px; }

    .footer-credentials p {
        text-align: center; } }

@media screen and (max-width: 690px) {
    .footer {
        &-block:nth-child(2) {
            display: none; }
        &-block:nth-child(3) {
            display: none; } }
    .footer-credentials {
        display: flex;
        justify-content: space-between;
        height: 105px; }
    .ecosystem {
        &-description {
            p {

                img {
                    width: 80%;
                    float: unset;
                    margin-left: auto;
                    margin-right: auto; }

                .ecosystem-description-leftimg {
                    float: unset; } } } } }


@media screen and (max-width: 576px) {
    .ecosystem {
        &-description {
            p {

                img {
                    width: 100%;
                    float: unset;
                    margin-left: auto;
                    margin-right: auto; }

                .ecosystem-description-leftimg {
                    float: unset; } } } }

    .floating-label-small label.small-floating {
        top: calc(30% - 7px);
        left: 7%; } }


@media screen and (max-width: 440px) {
    body, html {
        font-size: 14px; }

    .ecosystem {
        &-header {
            h1 {}

            img {
                width: 90%;
                margin-top: 150px; } }
        &-description {
            padding: 0; } }
    .feedback-coop {
        &-buttons {
            div {
                flex-direction: column;
                align-items: center; }
            button {
                display: block;
                margin-left: auto;
                margin-right: auto; } } }

    .ecosystem {
        &-description {
            h2 {
                margin-top: 0px; } } }




    .howwework-description-item {
        span {
            font-size: 12px; }
        img {
            width: 20px;
            height: 25px; } }

    .ecosystem {
        &-description {

            p {
                margin-bottom: 50px;
                img {
                    width: 100%;
                    float: unset;
                    margin-left: auto;
                    margin-right: auto; } } } }


    .feedback-coop-form-input input[type="checkbox"]:checked + label:after {
        left: 4px;
        top: -10px; }

    .feedback-big-form-input input[type="checkbox"] + label:before {
        width: 55px;
        height: 30px; }

    .feedback-coop-form-input .checkbox {
        width: 300px; }


    .instagram .instagram-hover-text {
        top: 0%;
        padding: 0px;
        font-size: 10px; } }



@media screen and (max-width: 400px) {
    .floating-label-small label.small-floating {
        left: 10%; } }
