
.feedback-small {
    background-image: url('./images/Feedback/feedback-small-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 170px;

    &.feedback-application {
        background: #0F75BC;
        box-shadow: 0px 15px 30px rgba(0, 89, 151, 0.2);
        border-radius: 16px;
        margin-top: 30px;
        width: 100%;
        height: 208px;
        padding: 72px 0 44px;
        position: relative;
        background-size: cover;
        background-image: url('./images/Feedback/application-bg.png');
        @media (max-width: 768px) {
            height: auto;
            padding: 68px 0 30px; }
        @media (max-width: 577px) {
            padding: 45px 0 30px;
            margin-top: 22px; }

        .applicationLabel {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: 577px) {
                img {
                    width: 257px; } } }

        .container {
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: auto;

            p {
                text-align: center;
                width: 100%;
                @media (max-width: 577px) {
                    padding: 0;
                    margin-top: 0; } } }

        .feedback-form-small-submit-button {
            color: #0F75BC;
            background: #ffffff;
            border-radius: 50px;
            width: 250px;
            height: 52px;
            margin: 0 auto;
            display: block; }

        &.left-align {
            margin-top: 0;
            padding: 48px 54px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            .applicationLabel {
                position: static;
                transform: none; }
            .container {
                width: 100%;
                max-width: 100%;
                align-items: flex-start;
                p {
                    text-align: left;
                    max-width: 370px; } }
            @media (max-width: 768px) {
                height: auto;
                margin-top: 30px;
                padding: 68px 0 30px;
                flex-direction: column;
                .feedback-form-small-submit-button {
                    margin: 0 auto;
                    display: block; }
                .applicationLabel {
                    position: absolute;
                    transform: translate(-50%, -50%); }
                .container {
                    align-items: center;
                    p {
                        text-align: center;
                        max-width: 100%;
                        @media (max-width: 577px) {
                            padding: 0;
                            margin-top: 0; } } } }
            @media (max-width: 577px) {
                margin-top: 22px;
                padding: 45px 0 30px; } } }


    .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        p {
            font-family: 'Gotham Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            color: $white;
            width: 555px;
            @media (max-width: 768px) {
                font-size: 20px; } }
        strong {
            font-weight: 700; }

        h3 {
            font-weight: 300;
            color: $white;
            text-align: right;
            font-size: 18px; } }

    &-buttons {
        form {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; }
        input {
            width: 270px;
            height: 52px;

            border: none;
            border-radius: 50px 0 0 50px;
            text-indent: 20px;
            line-height: 52px;
            padding: 0;
            &:focus {
                border: 1px solid $blue; }

            &::placeholder {
                font-family: 'Gotham Pro';
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                vertical-align: middle; } }


        button {
            font-family: 'Gotham Pro';
            font-style: normal;
            font-size: 16px;
            width: 270px;
            height: 52px;
            line-height: 52px;

            background-color: $white;

            border: none;
            border-radius: 0 50px 50px 0;
            color: $blue;
            padding: 0;
            text-transform: uppercase;
            vertical-align: middle;
            &focus {
                border: none;
                background-color: $blue;
                color: $white; }
            &:hover {
                border: none;
                background-color: $darkblue;
                color: $white; } } } }


.floating-label-small {
    position: relative;
    text-align: center; }

.floating-label-small label {
    position: absolute;
    top: calc(50% - 7px);
    left: 25%;
    opacity: 0;
    transition: all .3s ease;

    &.small-floating {
        left: 11%; } }

.floating-label-small input:not(:placeholder-shown) {
    padding: 28px 0px 12px 10px; }

.floating-label-small input:not(:placeholder-shown) + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    opacity: .7; }


.floating-label-small textarea:not(:placeholder-shown) {
    padding: 28px 0px 12px 10px; }

.floating-label-small textarea:not(:placeholder-shown) + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    opacity: .7; }

.feedback-form-small-phone-input-filled {
    background-color: $lightGray; }

.feedback-small-buttons .feedback-form-small-submit-button {
    background-color: $lightGray;
    color: $gray;
    &.filled {
        background-color: $blue;
        color: $white; } }
