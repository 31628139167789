.ecosystem {

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &-nav {
        padding-top: 25px;
        ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                margin: 0px 10px;
                a {
                    color: $blue;
                    text-decoration: none;
                    padding-bottom: 5px;
                    &:hover {
                        color: $darkblue;
                        border-bottom: 1px solid $darkblue; } } }
            span {
                color: $blue; } } }
    header {
        height: 690px; }

    &-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        &-video-bg {
            padding-top: 30px;
            align-items: center;
            justify-content: center;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                width: 100vw;
                height: calc(100% + 120px + 185px);
                transform: translate(0, 25px);
                background-size: cover;
                background-image: url("./images/Loxone/loxone-video-bg.png");
                @media (max-width: 1199px) {
                    display: none; } }
            .ecosystem-description {
                margin-bottom: 24px;
                position: relative;
                z-index: 1; }
            iframe {
                width: 100%;
                @media (max-width: 768px) {
                    height: calc((100vw - 120px) * 0.56); }
                @media (max-width: 560px) {
                    height: calc((100vw - 48px) * 0.56); } } } }

    &-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        h1 {
            font-family: 'Gotham Pro';
            font-weight: 700;
            font-style: normal;
            font-size: 36px;
            margin-top: 50px; }

        img {
            display: block;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 50px; }

        button {
            width: 265px;
            height: 50px;
            border-radius: 50px;
            border: none;
            font-family: 'Gotham Pro';
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            text-transform: uppercase;
            padding: 0;
            background-color: $blue;
            color: $white;
            margin-bottom: 150px; } }
    &-images {
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;

        img {
          margin-bottom: 10px; } }
    &-description {
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        &-wrapper {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap; }
        &-full {
            width: 100%;
            padding: 0 30px;
            position: relative;
            z-index: 1;
            @media (max-width: 1199px) {
                padding: 0; }
            &-form {
                width: 100%;
                position: relative;
                z-index: 1;
                padding: 0 90px;
                @media (max-width: 1199px) {
                    padding: 0; } } }
        &-gray {
            padding-top: 90px;
            width: 100vw;
            position: relative;
            left: calc((100% - 100vw) / 2);
            background: #FBFBFB;
            img {
                margin: 0 auto;
                display: block; }
            @media (max-width: 1199px) {
                padding-top: 50px;
                padding-bottom: 50px; }
            @media (max-width: 560px) {
                background: #ffffff; } }


        &-img {
            height: 470px;
            max-width: 100%;
            flex: 1 1 200px;
            margin: 0px auto; }


        h2 {
            font-size: 30px;
            margin-top: 60px;
            margin-bottom: 30px;
            text-align: center; }


        h3 {
            margin-bottom: 20px;

            &.h3-bold {
                width: 100%;
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 157.9%;
                margin-right: 5px; } }
        p {
            &.p-bold {
                font-weight: 700; }
            img {
                max-width: 450px;
                float: right;
                margin-left: 20px;
                margin-right: 20px;
                margin-top: -30px; }

            .ecosystem-description-leftimg {
                float: left; }

            span {
                display: block;
                height: 20px;
                &.p-bold {
                    height: auto;
                    font-weight: 700; } } }


        img {
            display: block;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto; }

        h5 {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 13px; }

        h6 {
            text-align: center;
            font-size: 13px; }

        ul {
            margin-top: 15px;
            padding-left: 15px;
            list-style-type: none;
            li {
                font-weight: 400;
                margin-top: 6px;
                margin-bottom: 6px;
                color: $dark; }



            li:before {
                content: '';
                display: inline-block;
                height: 10px;
                width: 10px;
                background-size: 10px;
                background-image: url("./images/list-img-active.svg");
                background-repeat: no-repeat;
                margin-right: 20px; } }

        &-siri {
            margin-top: 56px;
            &-block {
                margin-bottom: 75px;
                text-align: center;
                h4 {
                    font-weight: 700;
                    font-size: 32px;
                    background: linear-gradient(90deg, #F0357C, #F150E6 20.31%, #6B7DF7 37.5%, #37BDDE);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 10px; }
                span {
                    font-weight: 400;
                    font-size: 22px; } } }

        .ecosystem-row {
            .ecosystem-principles {
                h3 {
                    width: 100%;
                    margin-bottom: 40px;
                    text-align: center; } } }
        .ecosystem-description-float-loxone {
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                img {
                    float: none;
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                    border-radius: 8px;
                    margin: 53px 0 40px 0;
                    max-width: 100%;
                    order: 1;
                    @media (max-width: 560px) {
                        height: calc((100vw - 48px) * 0.55); }
                    @media (max-width: 440px) {
                        margin: 53px 0 20px 0; } }
                &-bottom {
                    display: flex;
                    flex-direction: column;
                    img {
                        float: none;
                        width: 100%;
                        height: 300px;
                        object-fit: cover;
                        border-radius: 8px;
                        margin: 0 0 53px 0;
                        max-width: 100%;
                        order: unset;
                        @media (max-width: 560px) {
                            height: calc((100vw - 48px) * 0.58); } } } } }
        .loxone-history-desktop-img {
            display: block;
            @media (max-width: 768px) {
                display: none; } }
        .loxone-history-mobile-img {
            display: none;
            @media (max-width: 768px) {
                display: block; } } }

    &-interface {
        margin-top: 50px; }

    &-margin-small {
        margin-bottom: 30px; }

    &-margin-big {
        margin-bottom: 90px;
        @media (max-width: 440px) {
            margin-bottom: 70px; } }

    &-margin-both {
        margin-bottom: 60px;
        margin-top: 90px; }

    &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        @media (max-width: 560px) {
            flex-direction: column;
            align-items: center; }
        &-button {
            padding: 0 40px;
            height: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            font-size: 16px;
            line-height: 75.2%;
            &.button-apply {
                background: #0F75BC;
                color: #ffffff;
                margin-right: 8px;
                width: 250px;
                @media (max-width: 560px) {
                    margin-right: 0;
                    margin-bottom: 18px; } }
            &.button-showroom {
                background: #ffffff;
                color: #0F75BC;
                width: 300px; } } }

    &-info-container {
        margin-top: -20px;
        margin-bottom: 4px; }

    &-principles {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        padding: 40px 50px;
        @media (max-width: 560px) {
            padding: 40px 10px; }

        .principles-items {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                justify-content: center; }
            @media (max-width: 560px) {
                padding: 40px; } }

        .principle-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: calc((100% - 80px) / 3);
            @media (max-width: 768px) {
                width: 100%;
                max-width: 410px;
                &:not(:last-child) {
                    margin-bottom: 40px; } }
            &-image {
                height: 121px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 14px;
                img {
                    margin: 0; } }
            &-title {
                margin-bottom: 14px;
                font-weight: 700;
                font-size: 18px;
                line-height: 1;
                text-align: center; }
            &-text {
                font-size: 13px;
                line-height: 21px;
                text-align: center; } } }

    &-middle-align {
        h2 {
            text-align: center;
            margin-bottom: 10px;
            margin-top: 40px;
            @media (max-width: 440px) {
                margin-top: 40px; } }
        .p-short {
            text-align: center;
            max-width: 712px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 40px; }
        .p-center {
            text-align: center;
            margin-bottom: 40px; }
        h3 {
            &.h3-bold {
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                font-weight: 700;
                font-size: 18px;
                line-height: 157.9%;
                margin-bottom: 0;
                text-align: center; } } }


    &-protocols {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-bottom: 40px;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center; }

        .ecosystem-protocol {
            padding: 30px;
            background: #FFFFFF;
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            width: calc(50% - 10px);
            @media (max-width: 768px) {
                width: 100%;
                &:not(:last-child) {
                    margin-bottom: 20px; } }
            @media (max-width: 400px) {
                padding: 30px 10px; }
            &-header {
                display: flex;
                align-items: center;
                margin-bottom: 30px; }
            &-icon {
                height: 61px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                img {
                    margin: 0; } }
            &-title {
                font-weight: 700;
                display: inline-block;
                margin-right: 5px; }
            p {
                margin-bottom: 0; } } }
    &-standards {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
        .ecosystem-standard {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 35px 30px;
            background: #FFFFFF;
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
            border-radius: 16px;
            @media (max-width: 768px) {
                padding: 30px;
                flex-direction: column;
                align-items: flex-start; }
            @media (max-width: 400px) {
                padding: 30px 10px; }
            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 61px;
                margin-right: 30px;
                @media (max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 30px; }
                @media (max-width: 400px) {
                    height: 51px; }
                img {
                    margin: 0;
                    height: 100%; } }
            &-title {
                display: inline-block;
                font-weight: 700;
                margin-right: 5px;
                font-size: 18px;
                line-height: 157.9%; }
            &-text {
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
                margin-bottom: 0; }
            &:not(:last-child) {
                margin-bottom: 20px; } } }

    &-image {
        &-full {
            width: 100%;
            img {
                width: 100%;
                margin: 0; } } }

    &-devices {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        position: relative;
        div {
            position: relative;
            height: 186px;
            display: flex;
            justify-content: center;
            align-items: center; }



        .control-hover-text {
            width: 250%;
            position: absolute;
            top: 33%;
            visibility: hidden;
            font-size: 13px;
            line-height: 21px;
            letter-spacing: 0.01em;
            color: $black; }
        p {
            font-weight: 300;
            font-size: 18px;
            color: $blue;
            margin-bottom: 22px !important; }
        img {
            width: initial;
            height: 100%; }

        &:hover {
            img {
                opacity: 0.2;
                transition: all 0.4s ease; }

            .control-hover-text {
                transition: all 0.4s ease;
                visibility: visible; } } }



    &-photo {
        margin-top: 60px;
        width: 100%;
        height: 100%; } }

.devices.control-devices {
    box-shadow: none; }

.devices.control-devices-bottom-space {
    margin-bottom: 60px; }

.ecosystem-devices-device-name {
    padding: 30px 0 0; }

.ecosystem-description.ecosystem-description-image-wrapper {
    padding: 0; }

.ecosystem-description-image {
    width: 100%; }

.ecosystem-description .amazon-alexa-description {
    margin-bottom: 10px; }

.apple-home-kit-image-morning,
.apple-home-kit-image-evening {
    width: 100%; }

.ecosystem-header-space-top {
    margin-top: 70px; }

.ecosystem-header-title {
    text-align: center; }

.ecosystem-description .ecosystem-description-key-features-title {
    margin: 70px 0 50px; }

.ecosystem-description-yandex-space-top {
    margin-top: 60px; }

.ecosystem-description {
    .ecosystem-description-yandex-music-image,
    .ecosystem-description-leftimg {
        margin-top: 5px; } }

.ecosystem-images {
    .yandex-reminder-image,
    .yandex-radio-image {
        width: 100%; } }

.loxone-video {
    margin-bottom: 40px; }

.loxone-root {
    h3 {
        color: #181818; }
    h2 {
        color: #181818; }
    p {
        color: #181818; }
    .ecosystem-description-float-loxone,
    .ecosystem-description-float-loxone-bottom {
        img {
            @media (min-width: 769px) {
                margin-left: 53px;
                margin-right: 0; } }
        .ecosystem-description-leftimg {
            @media (min-width: 769px) {
                margin-right: 53px;
                margin-left: 0; } } }

    .ecosystem-description h2 {
        @media (max-width: 440px) {
            margin-top: 60px; } }
    .ecosystem-description p {
        @media (max-width: 440px) {
            margin-bottom: 20px; } }
    .custom-container {
        @media (max-width: 1199px) {
            padding: 0 72px; }
        @media (max-width: 768px) {
            padding: 0 60px; }
        @media (max-width: 560px) {
            padding: 0 24px; }
        &.ecosystem-row {
            @media (max-width: 1199px) {
                padding: 0;
                @media (max-width: 768px) {
                    padding: 0; }
                @media (max-width: 560px) {
                    padding: 0; } } } }
    .loxone-header {
        margin-bottom: 210px;
        position: relative;
        height: 548px;
        @media (max-width: 1199px) {
            margin-bottom: 200px; }
        @media (max-width: 1022px) {
            margin-bottom: 170px;
            height: calc((100vw * 0.55)); }
        @media (max-width: 768px) {
            margin-bottom: 180px; }
        @media (max-width: 560px) {
            margin-bottom: calc(((100% - 18px) * 0.55 + 316px) - (100vw * 1.025));
            height: calc(100vw * 1.025);
            min-height: 414px; }
        @media (max-width: 424px) {
            margin-bottom: calc(((100% - 18px) * 0.55 + 316px) - 414px); } }
    .loxone-device {
        position: absolute;
        top: 262px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        max-width: 880px;
        @media (max-width: 1199px) {
            max-width: 880px;
            width: calc(100% - 142px); }
        @media (max-width: 768px) {
            width: calc(100% - 120px); }
        @media (max-width: 560px) {
            width: calc(100% - 18px);
            top: 316px; } }
    .loxone-header-top {
        position: relative;
        z-index: 2; }
    .ecosystem-devices-device-name {
        white-space: nowrap; }
    .hover-button {
        &:hover {
            color: #ffffff;
            background: $darkblue; } } }

.feedback-form-container-loxone.feedback-form-container {
    transition: none; }
.feedback-form-container-loxone-wrapper {
    height: 170px;
    &.feedback-form-container-loxone-wrapper-sent {
        height: unset; }
    &.feedback-form-container-loxone-wrapper-full {
        height: 1205px; } }
