
a {
    text-decoration: none;
    &:hover {
        text-decoration: none; } }

.block-thumbnail {

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        border-radius: 2px;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.18);
        margin-bottom: 22px;
        object-fit: cover;

        overflow: hidden; }
    .block-thumbnail-text {
        font-weight: 400;
        font-size: 13px;
        text-align: center; } }

.main-thumbnail-container {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.18); }

.main-thumbnail {
    height: 225px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 18px;
    margin-bottom: 18px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 2px;

    &:hover {
        box-shadow: 0px 0px 4px $blue;
        transition: all 0.4s ease; }

    img {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        padding: 0;

        object-fit: cover;
        object-position: 0 0;
        overflow: hidden; }
    p {
        color: $black;
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.01em;
        text-align: center;
        margin: 15px 10px; }

    &-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100%; } }


#instafeed {

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }


.instagram {
    position: relative;
    a {
        position: relative; }

    .instagram-hover-text {
        color: $black;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 0%;
        visibility: hidden;
        font-size: 13px;
        height: 100%;
        overflow-y: hidden;
        letter-spacing: 0.01em;
        position: absolute;
        padding: 0px 5px; }

    &:hover {
        .instagram-thumbnail img {
            opacity: 0.1;
            transition: all 0.4s ease; }

        .instagram-hover-text {
            transition: all 0.4s ease;
            visibility: visible; } } }


.instagram-container {
    padding-left: 110px;
    padding-right: 110px;

    margin-bottom: 125px; }

.instagram-thumbnail {

    margin: 15px 0px;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
    a {
        display: block;
        width: 100%;
        height: 100%; }
    img {
        width: 100%;
        max-width: 260px; } }
