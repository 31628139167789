.page-description .text-block-without-space,
.text-block-without-space {
    margin-bottom: 0; }

.page-description.container-space-top-90,
.container-space-top-90 {
    margin-top: 90px; }

.page-description.container-space-top-70,
.container-space-top-70 {
    margin-top: 70px; }

.page-description.container-space-top-60,
.container-space-top-60 {
    margin-top: 60px; }

.page-description.container-space-top-50,
.container-space-top-50 {
    margin-top: 50px; }
