$blue: #0F75BC;
$darkblue: #075993;
$black: #000000;
$white: #ffffff;
$gray: #818181;
$green: #34CA00;
$cyanBlue: #6ba6d0;
$venetianRed: #DB0020;
$dark: #212529;
$lightGray: #f7f7f7;
