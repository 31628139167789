.page-header .control-title {
    margin-top: 50px; }

.page-description.control-historical-reference-container {
    padding: 0; }

.control-historical-reference-container .control-historical-reference-text {
    &:first-of-type {
        margin-bottom: 30px; }
    &:last-of-type {
        margin-bottom: 50px; } }
