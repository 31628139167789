input:required {
    box-shadow: none; }

input, textarea:invalid {
    box-shadow: none; }




.feedback-coop {
    background-image: url('./images/Feedback/feedback-big-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    h2 {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        text-align: center;
        font-size: 30px;
        margin-top: 115px;
        margin-bottom: 45px; }
    &-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-bottom: 40px;
        div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            margin-left: auto;
            margin-right: auto;

            button {
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 170px;
                height: 52px;
                font-weight: 400;
                font-size: 16px;
                color: $white;
                background-color: $blue;
                border: none;
                margin: 12px 9px;
                padding: 0;
                padding-left: 16px;
                &:hover {
                    background-color: $darkblue; }

                &:focus {
                    background-color: $darkblue; } }

            .role-button {
                background-color: $blue;
                &:hover {
                    background-color: $blue; }
                &.active {
                    background-color: $darkblue; } }

            img {
                margin-right: 13px;
                width: 24px;
                height: 24px; } } }



    &-form {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        background-color: $white;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        margin-bottom: 126px;
        p {
            width: 362px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 64px;
            margin-bottom: 25px; }

        .not-valid-role {
            border-bottom: 1px solid $venetianRed; }

        a {
            color: $blue !important;
            padding-bottom: 5px;
            text-decoration: none;
            border-bottom: 1px solid $blue; }
        &-input {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: center;


            textarea {
                width: 362px;
                height: 120px;
                resize: none;
                &:focus {} }
            input[type="checkbox"] {
                margin: 0;
                width: unset;
                height: unset; }


            button {
                width: 262px;
                height: 52px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 39px;
                margin-bottom: 78px;
                background-color: $blue;
                color: $white;
                border-radius: 50px;
                padding: 0;
                font-size: 16px;
                text-transform: uppercase;
                border: none;
                &:hover {
                    background-color: $darkblue; } }


            .checkbox {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                align-items: center;
                width: 362px;

                label {
                    margin: 0;
                    font-size: 10px;
                    text-align: center; } }


            input[type="checkbox"] {
                margin: 0;
                width: unset;
                height: unset;
                position: absolute;
                opacity: 0;

                & + label {
                    position: relative;
                    cursor: pointer;
                    padding: 0; }

                & + label:before {
                    content: '';
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: middle;
                    width: 50px;
                    height: 35px;
                    background: $white;
                    border: 1px solid $gray;
                    border-radius: 2px;
                    &:hover {} }


                &:hover + label:before {
                    border: 1px solid $blue; }

                &:focus + label:before {
                    border: 1px solid $blue; }

                &:checked + label:before {
                    background: $white; }


                &:checked + label:after {
                    content: url('./images/checked.svg');
                    position: absolute;
                    left: 7px;
                    top: -8px;
                    font-size: 32px;
                    color: $blue;
                    width: 20px;
                    height: 20px; } } } } }

.floating-label .feedback-coop-form-label-message {
    top: calc(20% - 7px); }

.feedback-coop-form {
    .user-agreement-link,
    .user-personal-data-link {
        padding: 0; } }

.feedback-coop-form-input .submit-button-disabled {
    background-color: gray; }

.feedback-coop-form .application-accepted-text {
    text-align: center;
    margin-bottom: 55px; }
