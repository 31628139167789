.featuresRoot {
    padding-top: 30px;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start; }

    .feature {
        width: calc((100% - 130px) / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 90px;
        @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 40px; }
        @media (max-width: 440px) {
            margin-bottom: 20px; }
        &:nth-last-child(-n+3) {
            margin-bottom: 0;
            @media (max-width: 768px) {
                margin-bottom: 40px; } }
        &:last-child {
            margin-bottom: 0; }
        &-image {
            height: 536px;
            max-width: 100%;
            margin-bottom: 30px;
            @media (max-width: 1199px) {
                height: auto; }
            @media (max-width: 768px) {
                width: 314px;
                height: 630px;
                margin-bottom: 20px; }
            @media (max-width: 560px) {
                width: 184px;
                height: 368px; }
            img {
                max-width: 100%;
                margin: 0; } }
        &-title {
            margin-bottom: 15px;
            text-align: center;
            width: 100%;
            font-size: 20px;
            color: #000000; }
        &-description {
            font-weight: 400;
            font-size: 13px;
            line-height: 21px;
            text-align: center;
            margin-bottom: 0;
            width: 100%; } } }
