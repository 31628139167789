


.contacts {
    &-map {
        position: relative;
        width: 100%;
        height: 650px;

        &-fluid-wrapper {
            padding: 0;
            margin-top: 10px; }

        &-wrapper {
            width: 100%;
            height: 536px; }

        &-container-element,
        &-element {
            height: 100%; } }


    &-row {


        div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items:  center;
            text-align: center;
            img {
                width: 32px;
                height: 32px;
                margin-bottom: 26px; }
            a {
                margin-bottom: 110px;
                text-decoration: none;
                color: $black; } } } }

.page .contacts-header {
    height: unset; }
