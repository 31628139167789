
.services {
    background-image: url('./images/Feedback/feedback-big-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 130px;
    &-table {
        padding: 0 140px;
        h2 {
            margin-top: 65px;
            font-size: 30px; }
        h3 {

            margin-bottom: 25px; }
        p {
            font-weight: 300;
            font-size: 18px;
            margin-bottom: 60px; }
        h4 {
            font-weight: 700;
            font-size: 18px;
            color: $blue; }

        h5 {
            font-weight: 700;
            font-size: 18px;
            color: $blue;

            text-align: center; }

        &-header-row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            margin-bottom: 40px; }
        &-row {
            background-color: $white;
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);

            .col-xl-6 {
                border-bottom: 1px solid rgba(0, 0, 0, 0.13);
                border-right: 1px solid rgba(0, 0, 0, 0.13);
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items: center; }
            .col-xl-3 {
                border-bottom: 1px solid rgba(0, 0, 0, 0.13);
                border-right: 1px solid rgba(0, 0, 0, 0.13);
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items: center; }

            .col-xl-4 {
                border-bottom: 1px solid rgba(0, 0, 0, 0.13);
                border-right: 1px solid rgba(0, 0, 0, 0.13);
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items: center; }
            .col-xl-5 {
                border-bottom: 1px solid rgba(0, 0, 0, 0.13);

                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items: center; }
            img {
                margin-left: auto;
                margin-right: auto; }

            h4 {
                color: $gray; }

            h5 {
                color: $gray;
                word-break: break-word; }


            h4,h5, img {
                font-weight: 400;
                font-size: 16px;
                margin-top: 30px;
                margin-bottom: 30px; } } } }





.h4-table {
    padding-left: 36px;
    word-break: break-word; }
