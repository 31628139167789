.page-description.cooperation-container {
    margin: 125px 0; }

.cooperation {
    &-wrapper {
        text-align: center; } }

.page-description .cooperation {
    &-text-title {
        font-size: 30px; }

    &-text {
        font-size: 22px;
        margin-bottom: 0; } }
