.container {
  background-image: url('../../images/Feedback/feedback-small-bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 50px 20px;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;

  h2, p {
    text-align: center;
    color: #FFFFFF;
  }
}

.actionsContainer {
  display: flex;
  gap: 30px;
  align-items: center;
  max-width: 1100px;
  width: 100%;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  p {
    text-align: left;

    @media (max-width: 1100px) {
     text-align: center;
    }
  }

  > div {
    width: 100%;

    > form {
      display: flex;
      justify-content: center;
      width: 100%;

      button {
        margin-bottom: 0;
      }
    }
  }
}

.formSentMessage {
  text-align: center;
  color: #FFFFFF;
}
