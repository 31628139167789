.tab {
    &-list {
        border-bottom: 1px solid #ccc;
        padding-left: 0;
        &-item {
            display: inline-block;
            list-style: none;
            margin-bottom: -1px;
            padding: 0.5rem 0.75rem; }
        &-active {
            background-color: white;
            border: solid #ccc;
            border-width: 1px 1px 0 1px; } } }




.control {
    &-block {
        padding: 0 10%;
        button {
            width: 100%;
            border: none;
            height: 250px;
            background-color: transparent;
            border: none;
            &:hover {
                border-bottom: 1px solid $blue; }
            &.active {
                border-bottom: 1px solid $blue; } }

        &-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: inherit;
            align-items: center;
            margin-top: 30px;
            img {
                width: 60px;
                height: 55px; }
            h3 {
                font-weight: 400;
                font-size: 22px;
                color: $blue;
                margin-left: auto;
                margin-right: auto;
                margin-top: 35px; }
            &-button {
                width: 100%;
                button {
                    width: 90%;
                    height: 35px;
                    border-radius: 50px;
                    border: none;
                    font-family: 'Gotham Pro';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    text-transform: uppercase;
                    padding: 0;
                    background-color: $blue;
                    color: $white;
                    margin-bottom: 50px;

                    &:hover {
                        border: none; }

                    &.individual {
                        margin: 0; } } } } }


    &-devices {
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
        padding: 0 6%; }

    &-button {
            width: 265px;
            height: 50px;
            border-radius: 50px;
            border: none;
            font-family: 'Gotham Pro';
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            text-transform: uppercase;
            padding: 0;
            background-color: $blue;
            color: $white;
            margin-bottom: 50px; } }



.howwework {
    &-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        div {}

        button {
            width: 120px;
            border: none;
            text-transform: uppercase;
            background-color: transparent;
            border: none;
            padding: 0;
            border-bottom: 1px solid rgb(209,209,209);
            h3 {
                font-size: 18px; }
            p {
                font-size: 9px;
                margin-bottom: 20px;
                height: 25px;
                word-break: break-all; }
            &:focus {
                h3 {
                    color: $blue !important; }
                p {
                    color: $blue !important; }
                border-bottom: 1px solid $blue; }
            &:hover {
                h3 {
                    color: $blue !important; }
                p {
                    color: $blue !important; }
                border-bottom: 1px solid $blue !important; } }


        &-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: inherit;
            align-items: center;
            margin-top: 30px;
            h3 {
                font-weight: 400;
                font-size: 22px;
                color: $blue;
                margin-left: auto;
                margin-right: auto;
                margin-top: 35px; } }

        .slick-slider {
            width: 100%; }

        .slick-slide {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: center;
            max-width: 120px !important;
            div {
                width: 100%; } } }
    &-description {
        margin-top: 60px;
        margin-bottom: 120px;
        &-item {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
            width: 55%;
            height: 230px;
            h6 {
                position: absolute;
                font-size: 9px;
                color: $blue;
                text-transform: uppercase;
                max-width: 100px;
                word-break: break-all; }
            p {
                margin-bottom: 20px;
                font-weight: 300;
                font-size: 18px;
                padding: 0 60px;
                text-align: center; }
            div {
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: row; }
            span {
                color: $blue;
                margin-left: 13px;
                font-size: 18px; }

            button {

                border: none;
                background-color: transparent;
                width: 24px;
                height: 24px;
                margin-left: 10px;
                margin-right: 10px;

                img {
                    width: 14px;
                    height: 14px; } }

            .arrow {
                width: 36px;
                height: 36px;
                background-color: $white;
                box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
                border-radius: 2px;

                &:hover {
                    box-shadow: 0px 0px 4px $blue !important; } } } }







    &-devices {
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13); }

    &-button {
            width: 265px;
            height: 50px;
            border-radius: 50px;
            border: none;
            font-family: 'Gotham Pro';
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            text-transform: uppercase;
            padding: 0;
            background-color: $blue;
            color: $white;
            margin-bottom: 50px; } }







.authorize {
    &-block {
        button {
            width: 200px; } }
    &-description {
        margin-top: 60px;
        margin-bottom: 0px; } }

.page-description .howwework-description-item p {
    margin-bottom: 20px; }
