
.feedback-small {
    background-image: url('./images/Feedback/feedback-small-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 170px;

    &.feedback-small-showroom {
        @media (max-width: 577px) {
            .container p {
                padding: 0; } } }


    .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        p {
            font-family: 'Gotham Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            color: $white;
            width: 555px; }
        strong {
            font-weight: 700; }

        h3 {
            font-weight: 300;
            color: $white;
            text-align: right;
            font-size: 18px; } }

    &-buttons {
        form {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; }
        input {
            width: 270px;
            height: 52px;

            border: none;
            border-radius: 50px 0 0 50px;
            text-indent: 20px;
            line-height: 52px;
            padding: 0;
            &:focus {
                border: 1px solid $blue; }

            &::placeholder {
                font-family: 'Gotham Pro';
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                vertical-align: middle; } }


        button {
            font-family: 'Gotham Pro';
            font-style: normal;
            font-size: 16px;
            width: 270px;
            height: 52px;
            line-height: 52px;

            background-color: $white;

            border: none;
            border-radius: 0 50px 50px 0;
            color: $blue;
            padding: 0;
            text-transform: uppercase;
            vertical-align: middle;
            &focus {
                border: none;
                background-color: $blue;
                color: $white; }
            &:hover {
                border: none;
                background-color: $darkblue;
                color: $white; } } } }


.floating-label-small {
    position: relative;
    text-align: center; }

.floating-label-small label {
    position: absolute;
    top: calc(50% - 7px);
    left: 25%;
    opacity: 0;
    transition: all .3s ease;

    &.small-floating {
        left: 11%; } }

.floating-label-small input:not(:placeholder-shown) {
    padding: 28px 0px 12px 10px; }

.floating-label-small input:not(:placeholder-shown) + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    opacity: .7; }


.floating-label-small textarea:not(:placeholder-shown) {
    padding: 28px 0px 12px 10px; }

.floating-label-small textarea:not(:placeholder-shown) + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    opacity: .7; }

.feedback-form-small-phone-input-filled {
    background-color: $lightGray; }

.feedback-small-buttons .feedback-form-small-submit-button {
    background-color: $lightGray;
    color: $gray;
    &.filled {
        background-color: $blue;
        color: $white; }

    &.feedback-form-small-submit-button-showroom {
        @media (max-width: 577px) {
            width: 100%; } } }
