

.ecosystem-devices-p {
    padding-top: 30px;
    padding-bottom: 0px;
    width: 180px; }



.control-block-headers {
    padding: 0px; }


.stage-button {
    background-color: $lightGray;
    box-shadow: none;
    position: absolute;
    left: -30%; }

.stages-button-active-left {
    position: absolute;
    left: -30%;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13); }

.stages-button-active-right {
    position: absolute;
    right: -30%;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13); }

.control-block-row {
    flex-direction: row;
    justify-content: space-between; }

.control-block-header {
    width: calc(100% / 6); }

.h6-left {
    left: -20%; }

.h6-right {
    right: -20%; }
