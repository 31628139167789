@import '../../styles/colors';






.navbar {
    position: relative;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: 'Gotham Pro';
    font-weight: 400;
    font-style: normal;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0;
    &-ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        padding-top: 10px;
        margin: 0;
        height: 100%;
        width: 100%;
        li {
            display: block;
            list-style: none;
            font-size: 10px;
            a {
                text-decoration: none;
                color: $black;
                text-transform: uppercase;
                display: block;
                &:hover {
                    color: $blue; } } } }


    &-narrow {
        display: none;
        width: 100%;
        height: inherit;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &-list {
            position: absolute;
            top: 70px;
            display: none;
            flex-direction: column;
            align-items: center;
            background-color: {}
            padding: 0;
            padding-top: 10px;
            margin: 0;
            background-color: $white;
            width: 100%;
            z-index: 2000;
            li {
                display: block;
                list-style: none;
                margin: 13px 0px;
                font-size: 10px;
                a {
                    text-decoration: none;
                    color: $black;
                    text-transform: uppercase;
                    display: block;
                    padding: 10px 20px;
                    &:hover {
                        color: $blue; } } }


            &-dropdown {

                .col-md-6, .col-sm-10 {
                    margin-right: auto; }
                h4 {
                    padding: 0;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    justify-content: flex-start; }
                li {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    a {
                        padding: 0;
                        margin: 5px 0;
                        text-align: left; } } } }

        input {
            position: relative;
            width: 90%;
            border: none;
            margin-right: 29px;
            text-indent: 34px;
            font-size: 22px;

            &::placeholder {
                font-weight: 300;
                font-size: 22px; } } }


    &-logo {
        display: block;
        width: 180px; }

    &-search {
        width: 120px;
        height: 11px;
        background-color: #aeaeae; } }

.search-narrow-button {
    width: 265px;
    height: 50px;
    border-radius: 50px;
    border: none;
    font-family: 'Gotham Pro';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    padding: 0;
    background-color: $blue;
    color: $white;
    margin-left: auto;
    margin-right: auto; }

.navbar-icon-active {
    &:after,
    &:before,
    & div {
        content: '';
        background-image: url('./images/menu-bar-item.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 4px;
        display: block;
        width: 40px;
        margin: 0px;
        transition: all .2s ease-in-out; }

    &:before {
        transform: translateY(12px) rotate(45deg); }
    &:after {
        transform: translateY(-12px) rotate(-45deg); }
    & div {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0); } }

.search-narrow {
    &-delimiter {
        width: 100%;
        margin-left: auto;
        margin-right: auto; }
    div {
        flex-direction: row;
        div {
            width: 90%;
            margin-right: 40px;
            margin-bottom: 35px; }
        button {
            margin-left: auto;
            margin-right: auto; } } }


.search-icon {
    position: relative;
    padding-left: 18px; }

.search-icon::after {
    content: url('./images/search-active.svg');
    position: absolute;
    top: 1px;
    left: 0; }

.nav {

    .solutions-block {
        position: absolute;
        left: 0;
        top: 80px;

        flex-direction: column;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background: rgba(250, 250, 250, 0.88);
        padding-top: 30px;
        z-index: 5000;
        .row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: center;
            div {
                padding-left: 15px;
                padding-right: 15px; } } }




    .solutions-dropdown::after {
        content: url('./images/navbar-list-img.svg');
        position: absolute;
        right: -15px;
        top: 6%;
        width: 8px; }

    .solutions-dropdown:hover::after {
        content: url('./images/navbar-list-img-active.svg'); }


    .solutions-dropdown-active {
        position: relative; }

    .solutions-dropdown-active::after {
        content: url('./images/navbar-list-img-active.svg');
        position: absolute;
        right: -15px;
        top: 6%;
        width: 8px; }











    .services-block {
        width: 100%;
        position: absolute;
        left: 0;
        top: 80px;

        flex-direction: column;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background: rgba(250, 250, 250, 0.88);
        padding-top: 34px;
        padding-bottom: 56px;
        z-index: 5000;
        .row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: center; } }



    .services-thumbnail {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 155px;

        margin-top: 18px;
        margin-bottom: 18px;
        &:hover {
            box-shadow: 0px 0px 4px $blue; }
        img {
            width: inherit;
            height: inherit;
            border-radius: 2px;
            padding: 0;
            object-fit: cover;
            object-position: 50% 50%;
            overflow: hidden; }

        p {
            color: $black;
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            padding: 10px 0;
            margin: 0; } }

    .services-dropdown {
        position: relative;

        &:hover {
            .services-block {
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex; } } }

    .services-dropdown::after {
        content: url('./images/navbar-list-img.svg');
        position: absolute;
        right: -15px;
        top: 6%;
        width: 8px; }

    .services-dropdown:hover::after {
        content: url('./images/navbar-list-img-active.svg'); }


    .services-dropdown-active {
        position: relative; }

    .services-dropdown-active::after {
        position: absolute;
        content: url('./images/navbar-list-img-active.svg');
        right: -15px;
        top: 6%;
        width: 8px; } }

.services-thumbnail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 155px;

    margin-top: 18px;
    margin-bottom: 18px;
    &:hover {
        box-shadow: 0px 0px 4px $blue; }
    img {
        width: inherit;
        height: inherit;
        border-radius: 2px;
        padding: 0;
        object-fit: cover;
        object-position: 50% 50%;
        overflow: hidden; }

    p {
        color: $black;
        font-weight: 400;
        font-size: 13px;
        text-align: center;
        padding: 10px 0;
        margin: 0; } }

.solutions-dropdown {
        position: relative;

        &:hover {
            .nav .solutions-block {
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex; } } }


.closed-li-1, .closed-li-2, .closed-li-3, .closed-li-4, .closed-li-5, .closed-li-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }


.navbar-narrow {

    .solutions-block {
        margin-bottom: 0; }

    .services-block {
        position: absolute;
        left: 0;
        top: 80px;

        flex-direction: column;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background: rgba(250, 250, 250, 0.88);
        padding-top: 34px;
        padding-bottom: 56px;
        z-index: 5000;
        .row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: center; } }



    .services-thumbnail {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 155px;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.18);
        margin-top: 18px;
        margin-bottom: 18px;
        margin-left: auto;
        margin-right: auto;
        img {
            width: inherit;
            height: inherit;
            border-radius: 2px;
            padding: 0;
            object-fit: cover;
            object-position: 50% 50%;
            overflow: hidden; }

        p {
            text-transform: capitalize;
            color: $blue;
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            padding: 10px 0;
            margin: 0; } }

    .services-dropdown {
        position: relative;

        &:hover {
            .services-block {
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex; } } } }
