.blog-page {
	max-width: 1140px;
	margin-left: auto;
	margin-right: auto;
	&__main-title {
		font-family: 'Gotham Pro';
		font-weight: 700;
		font-style: normal;
		font-size: 28px;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 10px; }
	&__categories-block {
		display: flex;
		justify-content: center; }
	&__categories {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		justify-content: center;
		padding: 0;
		@media (max-width: 569px) {
			width: calc(100% - 30px); } }
	&__category {
		color: #000;
		border-radius: 50px;
		font-weight: 400;
		font-size: 16px;
		line-height: 45px;
		border: 3px solid #0f75bc;
		text-align: center;
		width: 265px;
		height: 50px;
		transition: color 0.1s, background-color 0.1s;
		cursor: pointer;
		margin: 0 10px;
		margin-top: 10px;
		@media (max-width: 569px) {
			width: 100%;
			margin: 0; }
		&-link {
			@media (max-width: 569px) {
				width: calc(50% - 5px);
				display: block;
				margin-bottom: 10px;
				&:nth-child(2n+1) {
					margin-right: 5px; }
				&:nth-child(2n) {
					margin-left: 5px; }
				@media (max-width: 340px) {
					width: 100%;
					margin: 0 0 10px 0 !important; } } }
		&_active {
			background-color: #0f75bc;
			color: white; } }
	&__preview {
		width: calc(50% - 5px);
		display: block;
		position: relative;
		margin-bottom: 10px;
		padding: 0 15px;
		@media (max-width: 800px) {
			width: 100%; }
		&:nth-child(2n+1) {
			margin-right: 5px;
			@media (max-width: 800px) {
				margin-right: 0; } }
		&:nth-child(2n) {
			margin-left: 5px;
			@media (max-width: 800px) {
				margin-left: 0; } }
		&-list {
			display: flex;
			flex-wrap: wrap;
			@media (max-width: 800px) {
				flex-direction: column; } }
		&-item {
			position: relative; }
		&-info {
			color: #000;
			background-color: #fff;
			line-height: 30px;
			text-align: center;
			width: 165px;
			height: 30px;
			border-radius: 50px;
			font-weight: 400;
			font-size: 16px; }
		&-category {
			position: absolute;
			top: 10px;
			left: 10px; }
		&-date {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 135px; } } }

.blog-page__categories-block + .blog-page__categories-block {
	display: block; }


