

.search {
    &-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 95px;
        margin-top: 35px;
        div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin-top: 55px; }
        div::after {
            content: url('./images/search.svg');
            position: absolute;
            width: 18px;
            height: 18px;
            left: 25px;
            top: 20px; }
        button {
            width: 265px;
            height: 50px;
            border-radius: 50px;
            border: none;
            font-family: 'Gotham Pro';
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            text-transform: uppercase;
            padding: 0;
            background-color: $blue;
            color: $white;
            margin-bottom: 150px;
            &:hover {
                background-color: $darkblue; } }




        input {
            position: relative;
            width: 90%;
            border: none;
            border-bottom: 1px solid #c4c4c4;
            margin-right: 29px;
            text-indent: 70px;
            font-size: 22px;
            padding-bottom: 10px; }

        img {
            width: 18px;
            height: 18px;
            position: absolute;
            left: 20px;
            top: 15px;

            &::placeholder {
                font-weight: 300;
                font-size: 22px; } } } }



.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        border: none;
        background-color: transparent;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        margin-right: 10px;
        &:hover {
            color: $blue;
            border-bottom: 1px solid $blue; }
        img {
            width: 14px;
            height: 14px; } }

    .arrow {
        width: 36px;
        height: 36px;
        background-color: $white;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
        border-radius: 2px;
        &:hover {
            border-bottom: none; } } }

.search-header-title {
    font-weight: 700; }

.search-form {
    width: 100%; }

.search-header .search-button {
    margin-bottom: 0;
    &.disabled {
        background-color: $lightGray;
        color: $gray; } }

.search-results-title {
    margin-bottom: 60px; }

.search-nothing-found-title {
    margin-top: 30px; }
