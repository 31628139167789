
@import './styles/fonts';
@import './styles/colors';


@import './components/Footer/Footer.sass';
@import './components/Tabs/Control/Control.sass';

@import './components/FeedbackForm/FeedbackFormSmall.sass';
@import './components/FeedbackForm/FeedbackFormBig.sass';
@import './components/FeedbackForm/FeedbackFormCoop.sass';
@import './components/FeedbackForm/ApplicationForm.sass';

@import './components/Services/Table/Table.sass';
@import './components/BlockThumbnail/BlockThumbnail.sass';
@import './components/Solutions/SolutionsBlock/SolutionsBlock.sass';
@import './components/NotFound/NotFound.sass';
@import './components/Search/Search.sass';
@import './components/Ecosystems/Ecosystems.sass';
@import './components/Contacts/Contacts.sass';
@import './components/BlogPage/BlogPage.sass';
@import './components/BlogConstructor/BlogConstructor.sass';
@import './components/Navbar/Navbar.sass';
@import './components/FeedbackForm/DragnDrop.sass';
@import './components/Tabs/Tabs.sass';
@import './components/Control/Control.sass';
@import './components/Cooperation/Cooperation.sass';
@import './components/Main/Main.sass';
@import './components/Services/Services.sass';
@import './components/Tabs/AuthorizeStages/AuthorizeStagesBlock.sass';
@import './components/ServicesBlock/ServicesBlock.sass';
@import './components/InfoBlock/InfoBlock.sass';
@import './components/FeaturesBlock/FeaturesBlock.sass';


body, html {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.01em;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    overflow-x: hidden;
    width: 100%;
    padding-right: 0; }

*:focus {
    outline: 0 !important; }

p, h3, h2, h1, span, button {
    letter-spacing: 0.01em; }


h3 {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 22px; }

p {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 18px; }

.scroll-top {
    background-color: $white;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
    padding: 16px; }

.preloader {
    width: 100vw;
    height: 100vh;
    opacity: 0.8; }

.footer-delimiter {
    border-top: 2px solid;
    border-width: 2px;
    -moz-border-image: url('./images/Footer/delimiter.svg') 30;
    -webkit-border-image: url('./images/Footer/delimiter.svg') 30;
    -o-border-image: url('./images/Footer/delimiter.svg') 30;
    border-image: url('./images/Footer/delimiter.svg') 30;
    border-image-width: auto;
    border-image-repeat: initial;
    border-image-outset: 5px 0 15px 0;
    border-bottom: none; }

.footer-ssr {
    display: flex;
    flex-direction: row;
    justify-content: center; }

.modal-open {
    margin-right: 17px; }


.page {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 60px;
    &-nav {
        padding-top: 25px;
        ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                margin: 0px 5px;
                color: $blue;
                a {
                    color: $blue;
                    text-decoration: none;
                    padding-bottom: 5px;
                    &:hover {
                        color: $darkblue;
                        border-bottom: 1px solid $darkblue; } } }
            span {
                color: $blue; } } }
    header {
        height: 460px; }
    &-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column; }
    &-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-family: 'Gotham Pro';
            font-weight: 700;
            font-style: normal;
            font-size: 28px;
            margin-top: 120px;
            margin-bottom: 50px; }

        button {
            width: 265px;
            height: 50px;
            border-radius: 50px;
            border: none;
            font-family: 'Gotham Pro';
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            text-transform: uppercase;
            padding: 0;
            background-color: $blue;
            color: $white; }

        span {
            color: $blue;
            margin-bottom: 38px; }
        p {
            font-weight: 300;
            font-size: 18px;
            text-align: center;
            padding: 0 85px; }

        input {
            position: relative;
            width: 656px;
            border: none;
            border-bottom: 1px solid #c4c4c4;
            margin-right: 29px;
            text-indent: 70px;
            font-size: 22px;
            padding-bottom: 10px;



            &::placeholder {
                font-weight: 300;
                font-size: 22px; } } }

    &-description {
        margin-top: 30px;

        h2 {
            text-align: center;
            font-weight: 400;
            font-size: 30px; }

        h3 {
            font-size: 22px;
            margin-bottom: 25px; }
        p {
            font-size: 18px;
            margin-bottom: 50px;
            color: $black;
            img {
                float: right; }

            span {
                display: block;
                height: 20px; } }

        a {
            color: $black;
            text-decoration: none; }

        ul {
            margin-top: 15px;
            padding-left: 15px;
            list-style-type: none;
            li {
                font-weight: 400;
                margin-top: 6px;
                margin-bottom: 6px;
                color: $dark; }



            li:before {
                content: '';
                display: inline-block;
                height: 10px;
                width: 10px;
                background-size: 10px;
                background-image: url("./images/list-img-active.svg");
                background-repeat: no-repeat;
                margin-right: 20px; } } }


    &-products {

        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div {
            width: 170px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                width: 167px;
                height: 122px;
                margin-bottom: 20px; }
            span {
                font-weight: 400;
                font-size: 13px;
                text-align: center; } } }
    &-photo {
        margin-top: 60px;
        margin-bottom: 30px;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto; } }



.slick-prev {
    left: 0%;
    z-index: 1;
    background-color: $white;
    background-image: url('./images/chevron-left-active.svg');
    background-size: 13px 13px;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
    padding: 18px;
    border: 1px solid transparent;
    border-radius: 2px;
    &:hover {
        color: unset;
        background-color: $white;
        background-image: url('./images/chevron-left-active.svg');
        background-size: 13px 13px;
        background-position: center center;
        background-repeat: no-repeat;
        box-shadow: 0px 0px 4px $blue; }
    &:before {
        content: ''; } }

.slick-arrow.slick-prev.slick-disabled {
    background-image: url('./images/chevron-left.svg');
    background-size: 13px 13px;
    background-position: center center;
    background-repeat: no-repeat;
    &:hover {
        background-color: $lightGray;
        background-image: url('./images/chevron-left.svg');
        background-size: 13px 13px;
        background-position: center center;
        background-repeat: no-repeat; } }

.slick-next {
    right: 0%;
    z-index: 1;
    background-color: $white;
    background-image: url('./images/chevron-right-active.svg');
    background-size: 13px 13px;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
    padding: 18px;
    border: 1px solid transparent;
    border-radius: 2px;
    &:hover {
        color: unset;
        background-color: $white;
        background-image: url('./images/chevron-right-active.svg');
        background-size: 13px 13px;
        background-position: center center;
        background-repeat: no-repeat;
        box-shadow: 0px 0px 4px $blue; }
    &:before {
        content: ''; } }



.slick-arrow.slick-next.slick-disabled {
        background-image: url('./images/chevron-right.svg');
        background-size: 13px 13px;
        background-position: center center;
        background-repeat: no-repeat;
        &:hover {
            background-color: $lightGray;
            background-image: url('./images/chevron-right.svg');
            background-size: 13px 13px;
            background-position: center center;
            background-repeat: no-repeat; } }

.slick-disabled {
    background-color: $lightGray; }




.hover-button:hover {
    background-color: $darkblue;
    border: 1px solid $darkblue; }



.modal-backdrop {
    background-color: #001221; }

.modal-backdrop.show {
    opacity: 0.9; }


.services-block-pages {
    position: absolute;
    left: 0;
    top: 80px;
    background: rgba(250, 250, 250, 0.88);
    flex-direction: column;


    padding-top: 34px;
    padding-bottom: 56px;
    z-index: 5000;
    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center; } }

.solutions-block-pages {
    position: absolute;
    left: 0;
    top: 80px;

    flex-direction: column;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: rgba(250, 250, 250, 0.88);
    padding-top: 30px;
    z-index: 5000;
    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        div {
            padding-left: 15px;
            padding-right: 15px; } } }


.blurred-background {
  background-color: $white;
  background-size: cover;
  overflow: hidden;
  filter: blur(13px);
  position: absolute;
  opacity: 0.97;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px; }


.control-header {
    height: unset; }

.tabs-render-ssr {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }

.tabs-render-ssr-div {
    display: flex;
    align-items: center; }

rect {
    fill: $white; }



.slick-list {
    display: flex; }

.slick-track {
    display: flex;
    justify-content: space-between; }

.modal-close-button {
    position: absolute;
    top: 25px;
    right: 20px; }








@import './styles/media';
