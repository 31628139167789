.servicesFade {
    transition-duration: .7s; }

.blocksContainer {
    padding: 0 30px;
    position: relative;
    max-width: 1600px;
    margin: 0 auto;

    @media (max-width: 650px) {
        padding: 0 15px; } }

.services-block {
    text-align: center; }

.services-block-thumbnail {
    height: 230px;

    @media (max-width: 1400px) {
        height: 200px; }

    @media (max-width: 1200px) {
        height: 160px; }

    @media (max-width: 991px) {
        height: 230px; }

    @media (max-width: 735px) {
        height: 200px; }

    @media (max-width: 650px) {
        height: 180px; }

    @media (max-width: 575px) {
        height: 250px; }

    @media (max-width: 475px) {
        height: 220px; }

    @media (max-width: 355px) {
        height: 180px; } }

.showMoreButton {
    width: 265px;
    height: 50px;
    border-radius: 50px;
    border: none;
    font-family: 'Gotham Pro';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    padding: 0;
    background-color: #0F75BC;
    color: #ffffff;
    margin: 20px auto;
    transition: opacity .7s ease-in-out, height .7s ease-in-out, visibility .7s ease-in-out, margin .7s ease-in-out;
    &.hide {
        opacity: 0;
        visibility: hidden;
        height: 0;
        margin: 0 auto; } }
