@import "src/styles/colors";

.container {

  & :global {

    strong {
      font-weight: 600;
    }

    .mt-40 {
      margin-top: 40px;
    }

    .mb-40 {
      margin-bottom: 40px;
    }

    .text-align-center {
      font-weight: 400;
      font-size: 20px;
      text-align: center;
    }

    .align-center {
      display: flex;
      align-items: center;
    }

    .price-table {
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
      border-radius: 16px;
      border-collapse: collapse;
      width: 100%;

      td, th {
        border-right: 1px solid #dddddd;
        padding: 10px;

        &:last-child {
          border-right: none;
        }
      }

      tr {
        border-bottom: 1px solid #dddddd;

        td:not(:nth-child(2)), th:not(:nth-child(2)) {
          text-align: center;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .tr-footer {
        font-weight: 700;
        color: $blue;

        td:nth-child(-n + 2) {
          border-right: none;
        }
      }

    }

    .rounded-text {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $blue;
      color: $white;
      font-weight: 700;
      font-size: 30px;
      border-radius: 50%;
    }

    .icon-item {
      margin: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 14px;
      text-align: center;

      p {
        padding: 0;
        margin: 0;
      }

      .image {
        padding: 22px;
        background-color: #F7F7F7;
        border-radius: 100px;

        > img {
          width: 80px;
          height: 80px;
        }
      }

      .title {
        font-weight: 600;
        font-size: 18px;
      }

      .description {
        font-size: 13px;
      }
    }

    .request-container {
      background-image: url('../../../images/Feedback/feedback-small-bg.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      padding: 50px 0;
      gap: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;

      h2 {
        color: $white;
      }

      button {
        padding: 15px 35px;
        text-transform: uppercase;
        border: none;
        border-radius: 40px;
        background-color: $white;
        color: $blue;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    .box-shadow-container {
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
      border-radius: 16px;
      padding: 20px 30px;
    }

    .header-container {
      height: 295px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-image: url("../../../images/Services/BuiltinV2/builtinv2-banner.jpg"), linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
      background-size: cover;
      background-blend-mode: overlay;
      position: relative;

      h1 {
        font-weight: 700;
        margin-bottom: 20px;
      }

      .header-actions {
        display: flex;
        flex-direction: column;
        gap: 8px;

        button {
          padding: 15px 35px;
          text-transform: uppercase;
          border: none;
          border-radius: 40px;

          &:hover {
            opacity: 0.9;
          }
        }

        .btn-submit {
          color: $white;
          background-color: $blue
        }

        .btn-callback {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: $white;
          color: $blue
        }


      }
    }

    #installation-work {
      margin-top: 60px;
      margin-bottom: 40px;

      > h2 {
        margin-bottom: 40px;
      }

      .installation-variant {
        margin-top: 40px;
        text-align: center;
        display: flex;
        flex-direction: column;

        .rounded-text {
          align-self: center;
          margin-bottom: 16px;
        }

        h3 {
          margin-bottom: 40px;
        }
      }
    }

    #advantages {
      margin-top: 40px;
      margin-bottom: 90px;

      display: flex;
      flex-direction: column;
      gap: 40px;

      > div {
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        padding: 20px 30px;
      }
    }

    #no-room-for-error {
      display: flex;
      flex-direction: column;
      gap: 40px;
      justify-content: center;
      margin-bottom: 90px;

      > h2, p {
        margin: 0;
        padding: 0;
      }

      > h2 {
        text-align: center;
      }

      .item {
        .rounded-text {
          margin: 0 auto;
        }
      }
    }

    #work-stages {
      margin-top: 90px;
      margin-bottom: 90px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      > .container {
        h2, p {
          margin: 0;
          padding: 0;
        }
      }

      h2 {
        text-align: center;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 40px;

        > div {
          display: flex;
          flex-direction: column;
          gap: 40px;
        }
      }

      .image-container {
        border-radius: 8px;
        overflow: hidden;
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .stage-number {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        p {
          font-size: 20px;
          font-weight: 400;
        }

      }
    }
  }
}
