



.not-found {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        text-align: center;
        font-weight: 700;
        font-size: 2.25rem;
        margin-top: 91px;
        margin-bottom: 53px; }
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        height: 100%; }

    button {
        width: 265px;
        height: 50px;
        border-radius: 50px;
        border: none;
        font-family: 'Gotham Pro';
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        text-transform: uppercase;
        padding: 0;
        background-color: $blue;
        color: $white;
        margin-top: 70px;
        margin-bottom: 90px; } }

.not-found-button-container {
    display: flex;
    justify-content: center; }
