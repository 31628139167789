.dragndrop {

    height: 104px;
    margin-left: auto;
    margin-right: auto;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    border: 1px dashed $gray;

    &.fileDropped {
        border: 1px solid $blue; }

    &.fileDropping {
        border: 1px dashed $blue; }

    &:focus {
        border: 1px dashed $blue;
        span {
            color: $blue; } }


    &-button {
        padding: 0 !important;
        margin: 0 !important;
        background-color: transparent !important;
        width: 12px !important;
        height: 17px !important;
        margin-right: 5px !important;
        z-index: 2000 !important;
        position: absolute;
        bottom: 25%;
        left: 25%;


        img {
            width: 12px;
            height: 12px; } }






    span {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: $gray;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        text-align: center;
        height: 100%;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        padding-bottom: 20px; }

    aside {


        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        p {

            margin: 0;
            font-size: 13px; } } }
