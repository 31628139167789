
.solutions {

    background-color: $lightGray;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 100px;
    padding: 0; }

.solutions-block {
    width: 100%;
    padding-bottom: 40px;
    margin-bottom: 90px;
    .row {
        justify-content: center; }
    .col-md-6 {
        margin-right: auto; }
    h3 {
        font-size: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 91px; }

    &.solutions-title-wrapper {
        margin-bottom: 0; }

    &-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: $white;
        width: 130px;
        height: 115px;

        flex-direction: column;
        justify-content: center;
        margin-bottom: 5px;
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
        color: $black;
        text-decoration: none;
        transition: all 0.5s;
        border-radius: 2px;
        &:hover {
            transition: all 0.4s ease;
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13), 0px 0px 5px $blue;
            color: $blue;
            text-decoration: none;

            path {
                fill: $blue;
                stroke: $blue; } }
        &:focus {

            border-bottom: 1px solid $blue;
            color: $blue;
            text-decoration: none;
            transform: translateY(-10px);
            transition: all 0.4s ease;
            path {
                fill: $blue;
                stroke: $blue; } }

        path {
            fill: $gray;
            stroke: $gray;
            stroke-width: 0%; }

        div {
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto; }
        p {
            font-size: 13px;
            text-align: center;
            margin-top: 20px; } }


    &-list {
        color: $blue;
        &-link {
            color: $blue;
            text-transform: none;
            padding: 0; }
        a {
            color: $blue;
            &:hover {
                h4 {
                    span {
                        border-bottom: 1px solid $blue; } } } }

        h4 {
            font-size: 22px;
            margin-bottom: 10px;
            width: 80%;
            text-align: left; }


        ul {
            font-size: 14px;
            margin-top: 15px;
            padding-left: 15px;
            list-style-type: none;
            li {
                margin-top: 6px;
                padding-bottom: 2px;
                &:hover {
                    a {
                        border-bottom: 1px solid $blue; } } }


            li:before {
                content: '';
                display: inline-block;
                height: 10px;
                width: 10px;
                background-size: 10px;
                background-image: url("./images/list-img.svg");
                background-repeat: no-repeat;
                margin-right: 20px; }

            li:hover:before {
                background-image: url("./images/list-img-active.svg"); }
            a {
                color: $blue; } } }






    .container {
        padding: 0px; }


    .slick-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        width: 140px; }


    .slick-track {
        margin-top: 10px; } }



@media screen and (max-width: 440px) {
    .solutions-block {
        .slick-slide {
            width: 130px !important; } }

    .solutions-block-item {
        width: 120px; } }


.button-active {
    p {
        color: $blue; }
    path {
        fill: $blue;
        stroke: $blue; } }
