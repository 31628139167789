






.footer {
    background-color: $lightGray;

    &-container {
        padding-top: 65px; }


    &-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between; }
    &-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        &-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row; } }

    &-contacts {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        &-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;
            padding-bottom: 2px;
            img {
                margin-right: 16px; } } }
    &-social-block {
        display: flex;
        justify-content: center; }
    &-social {
        margin-right: 5px;
        margin-bottom: 4px;
        display: block;
        img {
            width: 32px;
            height: 32px; } }
    &-credentials {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 11px;
        &-row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 40px; } }

    h4 {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        text-transform: uppercase;
        color: $black;
        margin-bottom: 25px;
        letter-spacing: 0.03em; }
    ul {
        padding: 0;
        margin-right: 20px; }
    li {
        list-style: none;
        margin-bottom: 12px; }

    a, button {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: rgba(0,0,0, 0.6);
        letter-spacing: 0.03em;
        &:hover {
            text-decoration: none; } }
    button {
        background-color: transparent;
        border: none;
        padding: 0; }
    p {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: rgba(0,0,0, 0.6);
        margin: 0; } }


.modal {
    z-index: 10000;
    &-title {
        width: 100%; }
    &-header {
        width: 100%;
        border: none;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;

        button {
            background-color: transparent;
            border: none; }

        h2 {
            font-weight: 400;
            font-size: 30px;
            text-align: center;
            margin-bottom: 20px; }

        img {
            width: 100%; } }
    &-body {
        h2 {
            text-align: center;
            font-weight: 400;
            font-size: 30px; }

        h3 {
            font-size: 22px;
            margin-bottom: 25px; }
        p {
            font-size: 18px;
            margin-bottom: 40px; } } }


.no-border {
    border: none !important; }

#footer-second {
    margin-right: 15px; }
