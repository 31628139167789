

.feedback-big {
    background-image: url('./images/Feedback/feedback-big-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    h2 {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        text-align: center;
        font-size: 30px;
        margin-top: 115px;
        margin-bottom: 66px; }
    &-form {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        background-color: $white;
        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.13);
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        margin-bottom: 126px;
        p {
            margin-top: 64px;
            padding: 0 50px;
            text-align: center;
            margin-bottom: 44px; }
        a {
            color: $blue !important;
            padding-bottom: 5px;
            text-decoration: none;
            border-bottom: 1px solid $cyanBlue; }
        &-input {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: center;


            textarea {
                width: 362px;
                height: 120px;
                resize: none;

                &:focus {} }


            .dragndrop {
                width: 362px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 24px;
                border-radius: 2px;
                padding: 0px 21px;
                &::placeholder {
                    font-family: 'Gotham Pro';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px; }

                &:focus {
                    border: 1px solid $blue; } }
            input[type="checkbox"] {
                margin: 0;
                width: unset;
                height: unset; }


            button {
                width: 262px;
                height: 52px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 39px;
                margin-bottom: 78px;
                background-color: $blue;
                color: $white;
                border-radius: 50px;
                padding: 0;
                font-size: 16px;
                text-transform: uppercase;
                border: none;
                &:hover {
                    background-color: $darkblue; } }


            .checkbox {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                align-items: center;
                width: 362px;

                label {
                    margin: 0;
                    font-size: 10px;
                    text-align: center; } }


            input[type="checkbox"] {
                margin: 0;
                width: unset;
                height: unset;
                position: absolute;
                opacity: 0;

                & + label {
                    position: relative;
                    cursor: pointer;
                    padding: 0; }

                & + label:before {
                    content: '';
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: middle;
                    width: 50px;
                    height: 35px;
                    background: $white;
                    border: 1px solid $gray;
                    border-radius: 2px; }

                &:hover + label:before {
                    border: 1px solid $blue; }

                &:focus + label:before {
                    border: 1px solid $blue; }

                &:checked + label:before {
                    background: $white; }


                &:checked + label:after {
                    content: url('./images/checked.svg');
                    position: absolute;
                    left: 7px;
                    top: -8px;
                    font-size: 32px;
                    color: $blue;
                    width: 20px;
                    height: 20px; } } } } }

.checkbox-personal-data {
    display: flex;
    align-items: center;
    justify-content: center; }

.not-valid:before {
    border: 1px solid $venetianRed !important; }

.feedback-big-form {
    .user-agreement-link,
    .user-personal-data-link {
        padding: 0; } }

label {
    font-size: 12.5px;
    color: $black;
    opacity: .8;
    font-weight: 400; }


input, textarea {

    font-size: 13px;
    padding: 20px 0px;
    width: 362px;
    height: 52px;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background: $white;
    box-sizing: border-box;
    transition: all .3s linear;
    color: $black;
    font-weight: 400;
    -webkit-appearance: none;
    border-radius: 2px;
    padding-left: 10px;
    border: 1px solid $gray; }



textarea:focus {
    border: 1px solid $blue;
    outline: 0;
    box-shadow: 0 2px 6px -8px rgba(182, 157, 230, 0.45); }

input:focus {
    border: 1px solid $blue;
    outline: 0;
    box-shadow: 0 2px 6px -8px rgba(182, 157, 230, 0.45); }


.floating-label {
    position: relative;

    text-align: center;
    margin: 12px 0; }

.floating-label label {
    position: absolute;
    top: calc(50% - 7px);
    left: 25%;
    opacity: 0.7;
    transition: all 1s ease;
    z-index: 3;

    &.feedback-form-big-label-message {
        top: calc(20% - 7px); } }

.floating-label input:focus {
    padding: 28px 0px 12px 10px; }

.floating-label input:focus + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    font-size: 70%;
    opacity: .7; }


.floating-label textarea:focus {
    padding: 28px 0px 12px 10px; }

.floating-label textarea:focus + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    font-size: 70%;
    opacity: .7; }


.floating-label input:valid {
    padding: 28px 0px 12px 10px; }




.floating-label input:valid + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    font-size: 70%;
    opacity: .7; }


.floating-label input:invalid {
    padding: 28px 0px 12px 10px; }


.floating-label input:not([value=""]):not(:focus):invalid {
    padding: 28px 10px 12px 10px;
    border: 1px solid $venetianRed; }

.floating-label input:not([value=""]):not(:focus):invalid + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    font-size: 70%;
    opacity: .7; }

.floating-label textarea:valid {
    padding: 28px 25px 12px 10px; }

.floating-label textarea:invalid {
    padding: 28px 25px 12px 10px; }


.floating-label textarea:valid + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    font-size: 70%;
    opacity: .7;
    z-index: 10; }


.floating-label textarea:valid {
    padding: 28px 25px 12px 10px; }




.floating-label .label-site:valid {
    padding: 28px 10px 12px 10px; }


.floating-label .label-site:valid + label {
    -webkit-transform: translateY(0px) {
            transform: translateY(0px); }
    font-size: 12.5px;
    opacity: .7; }

.floating-label .label-site:focus {
    padding: 28px 10px 12px 10px; }

.floating-label .label-site:focus + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    font-size: 70%;
    opacity: .7; }

.floating-label .label-site:invalid {
    padding: 28px 0px 12px 10px; }



.floating-label .label-site:not([value=""]):not(:focus):valid + label {
    -webkit-transform: translateY(-10px) {
            transform: translateY(-10px); }
    font-size: 70%;
    opacity: .7; }



.floating-label-div {
    position: absolute;
    top: 1px;
    left: 25%;
    width: 330px;
    height: 30px;
    background-color: $white;
    z-index: 2; }

.feedback-big-form-input .submit-button-disabled {
    background-color: gray; }

.feedback-big-form .application-accepted-text {
    margin-bottom: 55px; }

.modal-body .modal-user-agreement-text {
    margin-bottom: 0; }

.modal-block-space-top {
    margin-top: 20px;
    padding: 0; }
