.blogPage {
	margin-bottom: 30px !important; }

.blog-constructor {
	position: relative;
	&__info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: 0 15px;
		&-wrapper {
			margin: auto;
			margin-bottom: 30px;
			max-width: 1340px;
			padding: 0 100px;
			@media (max-width: 1199px) {
				padding: 0; } }
		&-logo {
			width: 140px;
			position: relative;
			left: -7px;
			&-img {
				width: 100%; } }
		&-date {
			color: #000;
			background-color: #fff;
			line-height: 27px;
			height: 27px;
			border-radius: 8px;
			font-weight: 400;
			font-size: 16px; }
		&-share {
			margin-left: 10px;
			color: #000;
			background-color: #fff;
			line-height: 27px;
			text-align: center;
			height: 27px;
			border-radius: 8px;
			font-weight: 400;
			font-size: 16px;
			padding: 4px 10px;
			display: flex;
			align-items: center;
			@media (max-width: 460px) {
				position: absolute;
				top: 8px;
				right: 10px; }
			&-icon {
				display: block;
				padding: 0 5px;
				&-image {
					width: 25px; } } } } }

.blog-page__title {
	margin-top: 160px !important; }
