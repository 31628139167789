.infoFade {
    transition-duration: .7s;
    margin-bottom: 30px; }

.InfoBlocksContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 75px;
    position: relative;
    max-width: 1600px;
    margin: 0 auto;

    @media (max-width: 1199px) {
        padding: 0 0;
        margin: 0 -15px; }

    @media (max-width: 650px) {
        padding: 0 0; } }

.info-block {
    text-align: center; }

.info-block-thumbnail {
    height: 168px; }

.info-block-thumbnail-name {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px; }
